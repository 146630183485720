import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIssuingCardQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIssuingCardQuery = { __typename?: 'Query', getIssuingCard: { __typename?: 'GetIssuingCardObjectzp6XBn6o', object?: { __typename?: 'GetIssuingCardObject', issuingCard?: { __typename?: 'IssuingCardObject', id: string, status: Types.IssuingCardStatus, isCardHolder: boolean, last4?: string | null, stripeIssuingCardId?: string | null, cardHolderStatus: Types.CardHolderStatus, treasuryFinancialAccountId?: string | null } | null } | null, error?: { __typename?: 'GetIssuingCardObjectzp6XBn6oErrorObject', code?: string | null, message: string } | null } };

export type ActivateCardIssuingMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ActivateCardIssuingMutation = { __typename?: 'Mutation', activateCardIssuing: { __typename?: 'BooleanP8pLfTLY', object?: boolean | null, error?: { __typename?: 'BooleanP8pLfTLYErrorObject', code?: string | null, message: string } | null } };

export type RequestIssuingCardMutationVariables = Types.Exact<{
  cardHolderInput: Types.CreateCardHolderInput;
}>;


export type RequestIssuingCardMutation = { __typename?: 'Mutation', requestIssuingCard: { __typename?: 'BooleanDQGNFXkr', object?: boolean | null, error?: { __typename?: 'BooleanDQGNFXkrErrorObject', code?: string | null, message: string } | null } };

export type GetEphemeralKeyQueryVariables = Types.Exact<{
  input: Types.GetEphemeralKeyInput;
}>;


export type GetEphemeralKeyQuery = { __typename?: 'Query', getEphemeralKey: { __typename?: 'GetEphemeralKeyObjectafJq7R2J', object?: { __typename?: 'GetEphemeralKeyObject', ephemeralKeySecret: string } | null, error?: { __typename?: 'GetEphemeralKeyObjectafJq7R2JErrorObject', code?: string | null, message: string } | null } };

export type CreateIssuingPayoutMutationVariables = Types.Exact<{
  input: Types.CreatedIssuingPayoutInput;
}>;


export type CreateIssuingPayoutMutation = { __typename?: 'Mutation', createIssuingPayout: { __typename?: 'CreatedIssuingPayoutObjectVvFgYFr0', object?: { __typename?: 'CreatedIssuingPayoutObject', id: string } | null, error?: { __typename?: 'CreatedIssuingPayoutObjectVvFgYFr0ErrorObject', code?: string | null, message: string } | null } };

export type ProcessPayoutMutationVariables = Types.Exact<{
  payoutId: Types.Scalars['String'];
}>;


export type ProcessPayoutMutation = { __typename?: 'Mutation', processPayout: { __typename?: 'BooleanBECH1Les', object?: boolean | null, error?: { __typename?: 'BooleanBECH1LesErrorObject', code?: string | null, message: string } | null } };

export type CreateBalanceTransferToIssuingMutationVariables = Types.Exact<{
  input: Types.ProcessBalanceTransferInput;
}>;


export type CreateBalanceTransferToIssuingMutation = { __typename?: 'Mutation', createBalanceTransferToIssuing: { __typename?: 'BooleanIzJOOuH8', object?: boolean | null, error?: { __typename?: 'BooleanIzJOOuH8ErrorObject', code?: string | null, message: string } | null } };

export type SendMonthlyBalanceTransactionExportMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SendMonthlyBalanceTransactionExportMutation = { __typename?: 'Mutation', sendMonthlyBalanceTransactionExport: { __typename?: 'BooleanFWsE0hui', object?: boolean | null, error?: { __typename?: 'BooleanFWsE0huiErrorObject', code?: string | null, message: string } | null } };

export type CreateIssuingTopupMutationVariables = Types.Exact<{
  input: Types.CreateIssuingTopupInput;
}>;


export type CreateIssuingTopupMutation = { __typename?: 'Mutation', createIssuingTopup: { __typename?: 'BooleanfpCPa2WW', object?: boolean | null, error?: { __typename?: 'BooleanfpCPa2WWErrorObject', code?: string | null, message: string } | null } };

export type GetBalanceTransactionDetailsQueryVariables = Types.Exact<{
  balanceTransactionId: Types.Scalars['String'];
  type: Types.BalanceTransactionType;
}>;


export type GetBalanceTransactionDetailsQuery = { __typename?: 'Query', getBalanceTransactionDetails: { __typename?: 'GetBalanceTransactionDetailsObject9N5fPdon', object?: { __typename?: 'GetBalanceTransactionDetailsObject', id: string, sourcePayment?: { __typename?: 'GetBalanceTransactionPaymentObject', formId: string, formName: string, firstName: string, lastName: string, email: string, createdAtUtc: GraphQL_Date, paymentMethod: Types.PaymentMethod, formType: Types.FormType } | null, sourcePayout?: { __typename?: 'GetBalanceTransactionPayoutObject', arrivalDate: GraphQL_Date } | null, sourceIssuingTransaction?: { __typename?: 'GetBalanceTransactionIssuingTransactionObject', merchantName: string, city?: string | null, state?: string | null, createdAtUtc: GraphQL_Date, authorizationCreatedAtUtc: GraphQL_Date } | null } | null, error?: { __typename?: 'GetBalanceTransactionDetailsObject9N5fPdonErrorObject', code?: string | null, message: string } | null } };

export type GetTransactionsForIssuingBalanceTransferQueryVariables = Types.Exact<{
  input: Types.ProcessBalanceTransferInput;
}>;


export type GetTransactionsForIssuingBalanceTransferQuery = { __typename?: 'Query', getTransactionsForIssuingBalanceTransfer: { __typename?: 'GetTransactionsForIssuingBalanceTransferObjectm8FYUj7P', object?: { __typename?: 'GetTransactionsForIssuingBalanceTransferObject', volume: number, endDate?: GraphQL_Date | null } | null, error?: { __typename?: 'GetTransactionsForIssuingBalanceTransferObjectm8FYUj7PErrorObject', code?: string | null, message: string } | null } };

export type ProcessIssuingRewardsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ProcessIssuingRewardsMutation = { __typename?: 'Mutation', processIssuingRewards: { __typename?: 'Boolean7F5i7dWi', object?: boolean | null, error?: { __typename?: 'Boolean7F5i7dWiErrorObject', code?: string | null, message: string } | null } };

export type GetIssuingRewardTransactionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIssuingRewardTransactionsQuery = { __typename?: 'Query', getIssuingRewardTransactions: { __typename?: 'GetIssuingRewardTransactionsObject0TjV4W8O', object?: { __typename?: 'GetIssuingRewardTransactionsObject', transactionCount: number, disputeCount: number, amount: number } | null, error?: { __typename?: 'GetIssuingRewardTransactionsObject0TjV4W8OErrorObject', code?: string | null, message: string } | null } };

export type CreatePlaidLinkTokenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreatePlaidLinkTokenMutation = { __typename?: 'Mutation', createPlaidLinkToken: { __typename?: 'String1iqiaIuA', object?: string | null, error?: { __typename?: 'String1iqiaIuAErrorObject', code?: string | null, message: string } | null } };


export const GetIssuingCardDocument = gql`
    query getIssuingCard {
  getIssuingCard {
    object {
      issuingCard {
        id
        status
        isCardHolder
        last4
        stripeIssuingCardId
        cardHolderStatus
        treasuryFinancialAccountId
      }
    }
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useGetIssuingCardQuery__
 *
 * To run a query within a React component, call `useGetIssuingCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuingCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuingCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIssuingCardQuery(baseOptions?: Apollo.QueryHookOptions<GetIssuingCardQuery, GetIssuingCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssuingCardQuery, GetIssuingCardQueryVariables>(GetIssuingCardDocument, options);
      }
export function useGetIssuingCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssuingCardQuery, GetIssuingCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssuingCardQuery, GetIssuingCardQueryVariables>(GetIssuingCardDocument, options);
        }
export type GetIssuingCardQueryHookResult = ReturnType<typeof useGetIssuingCardQuery>;
export type GetIssuingCardLazyQueryHookResult = ReturnType<typeof useGetIssuingCardLazyQuery>;
export type GetIssuingCardQueryResult = Apollo.QueryResult<GetIssuingCardQuery, GetIssuingCardQueryVariables>;
export const ActivateCardIssuingDocument = gql`
    mutation activateCardIssuing {
  activateCardIssuing {
    object
    error {
      code
      message
    }
  }
}
    `;
export type ActivateCardIssuingMutationFn = Apollo.MutationFunction<ActivateCardIssuingMutation, ActivateCardIssuingMutationVariables>;

/**
 * __useActivateCardIssuingMutation__
 *
 * To run a mutation, you first call `useActivateCardIssuingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCardIssuingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCardIssuingMutation, { data, loading, error }] = useActivateCardIssuingMutation({
 *   variables: {
 *   },
 * });
 */
export function useActivateCardIssuingMutation(baseOptions?: Apollo.MutationHookOptions<ActivateCardIssuingMutation, ActivateCardIssuingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateCardIssuingMutation, ActivateCardIssuingMutationVariables>(ActivateCardIssuingDocument, options);
      }
export type ActivateCardIssuingMutationHookResult = ReturnType<typeof useActivateCardIssuingMutation>;
export type ActivateCardIssuingMutationResult = Apollo.MutationResult<ActivateCardIssuingMutation>;
export type ActivateCardIssuingMutationOptions = Apollo.BaseMutationOptions<ActivateCardIssuingMutation, ActivateCardIssuingMutationVariables>;
export const RequestIssuingCardDocument = gql`
    mutation requestIssuingCard($cardHolderInput: CreateCardHolderInput!) {
  requestIssuingCard(cardHolderInput: $cardHolderInput) {
    object
    error {
      code
      message
    }
  }
}
    `;
export type RequestIssuingCardMutationFn = Apollo.MutationFunction<RequestIssuingCardMutation, RequestIssuingCardMutationVariables>;

/**
 * __useRequestIssuingCardMutation__
 *
 * To run a mutation, you first call `useRequestIssuingCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestIssuingCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestIssuingCardMutation, { data, loading, error }] = useRequestIssuingCardMutation({
 *   variables: {
 *      cardHolderInput: // value for 'cardHolderInput'
 *   },
 * });
 */
export function useRequestIssuingCardMutation(baseOptions?: Apollo.MutationHookOptions<RequestIssuingCardMutation, RequestIssuingCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestIssuingCardMutation, RequestIssuingCardMutationVariables>(RequestIssuingCardDocument, options);
      }
export type RequestIssuingCardMutationHookResult = ReturnType<typeof useRequestIssuingCardMutation>;
export type RequestIssuingCardMutationResult = Apollo.MutationResult<RequestIssuingCardMutation>;
export type RequestIssuingCardMutationOptions = Apollo.BaseMutationOptions<RequestIssuingCardMutation, RequestIssuingCardMutationVariables>;
export const GetEphemeralKeyDocument = gql`
    query getEphemeralKey($input: GetEphemeralKeyInput!) {
  getEphemeralKey(input: $input) {
    object {
      ephemeralKeySecret
    }
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useGetEphemeralKeyQuery__
 *
 * To run a query within a React component, call `useGetEphemeralKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEphemeralKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEphemeralKeyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEphemeralKeyQuery(baseOptions: Apollo.QueryHookOptions<GetEphemeralKeyQuery, GetEphemeralKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEphemeralKeyQuery, GetEphemeralKeyQueryVariables>(GetEphemeralKeyDocument, options);
      }
export function useGetEphemeralKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEphemeralKeyQuery, GetEphemeralKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEphemeralKeyQuery, GetEphemeralKeyQueryVariables>(GetEphemeralKeyDocument, options);
        }
export type GetEphemeralKeyQueryHookResult = ReturnType<typeof useGetEphemeralKeyQuery>;
export type GetEphemeralKeyLazyQueryHookResult = ReturnType<typeof useGetEphemeralKeyLazyQuery>;
export type GetEphemeralKeyQueryResult = Apollo.QueryResult<GetEphemeralKeyQuery, GetEphemeralKeyQueryVariables>;
export const CreateIssuingPayoutDocument = gql`
    mutation createIssuingPayout($input: CreatedIssuingPayoutInput!) {
  createIssuingPayout(input: $input) {
    object {
      id
    }
    error {
      code
      message
    }
  }
}
    `;
export type CreateIssuingPayoutMutationFn = Apollo.MutationFunction<CreateIssuingPayoutMutation, CreateIssuingPayoutMutationVariables>;

/**
 * __useCreateIssuingPayoutMutation__
 *
 * To run a mutation, you first call `useCreateIssuingPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssuingPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssuingPayoutMutation, { data, loading, error }] = useCreateIssuingPayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIssuingPayoutMutation(baseOptions?: Apollo.MutationHookOptions<CreateIssuingPayoutMutation, CreateIssuingPayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIssuingPayoutMutation, CreateIssuingPayoutMutationVariables>(CreateIssuingPayoutDocument, options);
      }
export type CreateIssuingPayoutMutationHookResult = ReturnType<typeof useCreateIssuingPayoutMutation>;
export type CreateIssuingPayoutMutationResult = Apollo.MutationResult<CreateIssuingPayoutMutation>;
export type CreateIssuingPayoutMutationOptions = Apollo.BaseMutationOptions<CreateIssuingPayoutMutation, CreateIssuingPayoutMutationVariables>;
export const ProcessPayoutDocument = gql`
    mutation processPayout($payoutId: String!) {
  processPayout(payoutId: $payoutId) {
    object
    error {
      code
      message
    }
  }
}
    `;
export type ProcessPayoutMutationFn = Apollo.MutationFunction<ProcessPayoutMutation, ProcessPayoutMutationVariables>;

/**
 * __useProcessPayoutMutation__
 *
 * To run a mutation, you first call `useProcessPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processPayoutMutation, { data, loading, error }] = useProcessPayoutMutation({
 *   variables: {
 *      payoutId: // value for 'payoutId'
 *   },
 * });
 */
export function useProcessPayoutMutation(baseOptions?: Apollo.MutationHookOptions<ProcessPayoutMutation, ProcessPayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessPayoutMutation, ProcessPayoutMutationVariables>(ProcessPayoutDocument, options);
      }
export type ProcessPayoutMutationHookResult = ReturnType<typeof useProcessPayoutMutation>;
export type ProcessPayoutMutationResult = Apollo.MutationResult<ProcessPayoutMutation>;
export type ProcessPayoutMutationOptions = Apollo.BaseMutationOptions<ProcessPayoutMutation, ProcessPayoutMutationVariables>;
export const CreateBalanceTransferToIssuingDocument = gql`
    mutation createBalanceTransferToIssuing($input: ProcessBalanceTransferInput!) {
  createBalanceTransferToIssuing(input: $input) {
    object
    error {
      code
      message
    }
  }
}
    `;
export type CreateBalanceTransferToIssuingMutationFn = Apollo.MutationFunction<CreateBalanceTransferToIssuingMutation, CreateBalanceTransferToIssuingMutationVariables>;

/**
 * __useCreateBalanceTransferToIssuingMutation__
 *
 * To run a mutation, you first call `useCreateBalanceTransferToIssuingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBalanceTransferToIssuingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBalanceTransferToIssuingMutation, { data, loading, error }] = useCreateBalanceTransferToIssuingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBalanceTransferToIssuingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBalanceTransferToIssuingMutation, CreateBalanceTransferToIssuingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBalanceTransferToIssuingMutation, CreateBalanceTransferToIssuingMutationVariables>(CreateBalanceTransferToIssuingDocument, options);
      }
export type CreateBalanceTransferToIssuingMutationHookResult = ReturnType<typeof useCreateBalanceTransferToIssuingMutation>;
export type CreateBalanceTransferToIssuingMutationResult = Apollo.MutationResult<CreateBalanceTransferToIssuingMutation>;
export type CreateBalanceTransferToIssuingMutationOptions = Apollo.BaseMutationOptions<CreateBalanceTransferToIssuingMutation, CreateBalanceTransferToIssuingMutationVariables>;
export const SendMonthlyBalanceTransactionExportDocument = gql`
    mutation sendMonthlyBalanceTransactionExport {
  sendMonthlyBalanceTransactionExport {
    object
    error {
      code
      message
    }
  }
}
    `;
export type SendMonthlyBalanceTransactionExportMutationFn = Apollo.MutationFunction<SendMonthlyBalanceTransactionExportMutation, SendMonthlyBalanceTransactionExportMutationVariables>;

/**
 * __useSendMonthlyBalanceTransactionExportMutation__
 *
 * To run a mutation, you first call `useSendMonthlyBalanceTransactionExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMonthlyBalanceTransactionExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMonthlyBalanceTransactionExportMutation, { data, loading, error }] = useSendMonthlyBalanceTransactionExportMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendMonthlyBalanceTransactionExportMutation(baseOptions?: Apollo.MutationHookOptions<SendMonthlyBalanceTransactionExportMutation, SendMonthlyBalanceTransactionExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMonthlyBalanceTransactionExportMutation, SendMonthlyBalanceTransactionExportMutationVariables>(SendMonthlyBalanceTransactionExportDocument, options);
      }
export type SendMonthlyBalanceTransactionExportMutationHookResult = ReturnType<typeof useSendMonthlyBalanceTransactionExportMutation>;
export type SendMonthlyBalanceTransactionExportMutationResult = Apollo.MutationResult<SendMonthlyBalanceTransactionExportMutation>;
export type SendMonthlyBalanceTransactionExportMutationOptions = Apollo.BaseMutationOptions<SendMonthlyBalanceTransactionExportMutation, SendMonthlyBalanceTransactionExportMutationVariables>;
export const CreateIssuingTopupDocument = gql`
    mutation createIssuingTopup($input: CreateIssuingTopupInput!) {
  createIssuingTopup(input: $input) {
    object
    error {
      code
      message
    }
  }
}
    `;
export type CreateIssuingTopupMutationFn = Apollo.MutationFunction<CreateIssuingTopupMutation, CreateIssuingTopupMutationVariables>;

/**
 * __useCreateIssuingTopupMutation__
 *
 * To run a mutation, you first call `useCreateIssuingTopupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssuingTopupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssuingTopupMutation, { data, loading, error }] = useCreateIssuingTopupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIssuingTopupMutation(baseOptions?: Apollo.MutationHookOptions<CreateIssuingTopupMutation, CreateIssuingTopupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIssuingTopupMutation, CreateIssuingTopupMutationVariables>(CreateIssuingTopupDocument, options);
      }
export type CreateIssuingTopupMutationHookResult = ReturnType<typeof useCreateIssuingTopupMutation>;
export type CreateIssuingTopupMutationResult = Apollo.MutationResult<CreateIssuingTopupMutation>;
export type CreateIssuingTopupMutationOptions = Apollo.BaseMutationOptions<CreateIssuingTopupMutation, CreateIssuingTopupMutationVariables>;
export const GetBalanceTransactionDetailsDocument = gql`
    query getBalanceTransactionDetails($balanceTransactionId: String!, $type: BalanceTransactionType!) {
  getBalanceTransactionDetails(
    balanceTransactionId: $balanceTransactionId
    type: $type
  ) {
    object {
      id
      sourcePayment {
        formId
        formName
        firstName
        lastName
        email
        createdAtUtc
        paymentMethod
        formType
      }
      sourcePayout {
        arrivalDate
      }
      sourceIssuingTransaction {
        merchantName
        city
        state
        createdAtUtc
        authorizationCreatedAtUtc
      }
    }
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useGetBalanceTransactionDetailsQuery__
 *
 * To run a query within a React component, call `useGetBalanceTransactionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBalanceTransactionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBalanceTransactionDetailsQuery({
 *   variables: {
 *      balanceTransactionId: // value for 'balanceTransactionId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetBalanceTransactionDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetBalanceTransactionDetailsQuery, GetBalanceTransactionDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBalanceTransactionDetailsQuery, GetBalanceTransactionDetailsQueryVariables>(GetBalanceTransactionDetailsDocument, options);
      }
export function useGetBalanceTransactionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBalanceTransactionDetailsQuery, GetBalanceTransactionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBalanceTransactionDetailsQuery, GetBalanceTransactionDetailsQueryVariables>(GetBalanceTransactionDetailsDocument, options);
        }
export type GetBalanceTransactionDetailsQueryHookResult = ReturnType<typeof useGetBalanceTransactionDetailsQuery>;
export type GetBalanceTransactionDetailsLazyQueryHookResult = ReturnType<typeof useGetBalanceTransactionDetailsLazyQuery>;
export type GetBalanceTransactionDetailsQueryResult = Apollo.QueryResult<GetBalanceTransactionDetailsQuery, GetBalanceTransactionDetailsQueryVariables>;
export const GetTransactionsForIssuingBalanceTransferDocument = gql`
    query getTransactionsForIssuingBalanceTransfer($input: ProcessBalanceTransferInput!) {
  getTransactionsForIssuingBalanceTransfer(input: $input) {
    object {
      volume
      endDate
    }
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useGetTransactionsForIssuingBalanceTransferQuery__
 *
 * To run a query within a React component, call `useGetTransactionsForIssuingBalanceTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsForIssuingBalanceTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsForIssuingBalanceTransferQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTransactionsForIssuingBalanceTransferQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionsForIssuingBalanceTransferQuery, GetTransactionsForIssuingBalanceTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionsForIssuingBalanceTransferQuery, GetTransactionsForIssuingBalanceTransferQueryVariables>(GetTransactionsForIssuingBalanceTransferDocument, options);
      }
export function useGetTransactionsForIssuingBalanceTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsForIssuingBalanceTransferQuery, GetTransactionsForIssuingBalanceTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionsForIssuingBalanceTransferQuery, GetTransactionsForIssuingBalanceTransferQueryVariables>(GetTransactionsForIssuingBalanceTransferDocument, options);
        }
export type GetTransactionsForIssuingBalanceTransferQueryHookResult = ReturnType<typeof useGetTransactionsForIssuingBalanceTransferQuery>;
export type GetTransactionsForIssuingBalanceTransferLazyQueryHookResult = ReturnType<typeof useGetTransactionsForIssuingBalanceTransferLazyQuery>;
export type GetTransactionsForIssuingBalanceTransferQueryResult = Apollo.QueryResult<GetTransactionsForIssuingBalanceTransferQuery, GetTransactionsForIssuingBalanceTransferQueryVariables>;
export const ProcessIssuingRewardsDocument = gql`
    mutation processIssuingRewards {
  processIssuingRewards {
    object
    error {
      code
      message
    }
  }
}
    `;
export type ProcessIssuingRewardsMutationFn = Apollo.MutationFunction<ProcessIssuingRewardsMutation, ProcessIssuingRewardsMutationVariables>;

/**
 * __useProcessIssuingRewardsMutation__
 *
 * To run a mutation, you first call `useProcessIssuingRewardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessIssuingRewardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processIssuingRewardsMutation, { data, loading, error }] = useProcessIssuingRewardsMutation({
 *   variables: {
 *   },
 * });
 */
export function useProcessIssuingRewardsMutation(baseOptions?: Apollo.MutationHookOptions<ProcessIssuingRewardsMutation, ProcessIssuingRewardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessIssuingRewardsMutation, ProcessIssuingRewardsMutationVariables>(ProcessIssuingRewardsDocument, options);
      }
export type ProcessIssuingRewardsMutationHookResult = ReturnType<typeof useProcessIssuingRewardsMutation>;
export type ProcessIssuingRewardsMutationResult = Apollo.MutationResult<ProcessIssuingRewardsMutation>;
export type ProcessIssuingRewardsMutationOptions = Apollo.BaseMutationOptions<ProcessIssuingRewardsMutation, ProcessIssuingRewardsMutationVariables>;
export const GetIssuingRewardTransactionsDocument = gql`
    query getIssuingRewardTransactions {
  getIssuingRewardTransactions {
    object {
      transactionCount
      disputeCount
      amount
    }
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useGetIssuingRewardTransactionsQuery__
 *
 * To run a query within a React component, call `useGetIssuingRewardTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuingRewardTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuingRewardTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIssuingRewardTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetIssuingRewardTransactionsQuery, GetIssuingRewardTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssuingRewardTransactionsQuery, GetIssuingRewardTransactionsQueryVariables>(GetIssuingRewardTransactionsDocument, options);
      }
export function useGetIssuingRewardTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssuingRewardTransactionsQuery, GetIssuingRewardTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssuingRewardTransactionsQuery, GetIssuingRewardTransactionsQueryVariables>(GetIssuingRewardTransactionsDocument, options);
        }
export type GetIssuingRewardTransactionsQueryHookResult = ReturnType<typeof useGetIssuingRewardTransactionsQuery>;
export type GetIssuingRewardTransactionsLazyQueryHookResult = ReturnType<typeof useGetIssuingRewardTransactionsLazyQuery>;
export type GetIssuingRewardTransactionsQueryResult = Apollo.QueryResult<GetIssuingRewardTransactionsQuery, GetIssuingRewardTransactionsQueryVariables>;
export const CreatePlaidLinkTokenDocument = gql`
    mutation createPlaidLinkToken {
  createPlaidLinkToken {
    object
    error {
      code
      message
    }
  }
}
    `;
export type CreatePlaidLinkTokenMutationFn = Apollo.MutationFunction<CreatePlaidLinkTokenMutation, CreatePlaidLinkTokenMutationVariables>;

/**
 * __useCreatePlaidLinkTokenMutation__
 *
 * To run a mutation, you first call `useCreatePlaidLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaidLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaidLinkTokenMutation, { data, loading, error }] = useCreatePlaidLinkTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePlaidLinkTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaidLinkTokenMutation, CreatePlaidLinkTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaidLinkTokenMutation, CreatePlaidLinkTokenMutationVariables>(CreatePlaidLinkTokenDocument, options);
      }
export type CreatePlaidLinkTokenMutationHookResult = ReturnType<typeof useCreatePlaidLinkTokenMutation>;
export type CreatePlaidLinkTokenMutationResult = Apollo.MutationResult<CreatePlaidLinkTokenMutation>;
export type CreatePlaidLinkTokenMutationOptions = Apollo.BaseMutationOptions<CreatePlaidLinkTokenMutation, CreatePlaidLinkTokenMutationVariables>;